import dashboard from "./menu/dashboard";
import oppHeader from "./menu/oppHeader";
import outlet from "./menu/outlet";
import product from "./menu/product";
import order from "./menu/order";
import configurationHeader from "./menu/configurationHeader";
import user from "./menu/user";

export default [
  ...dashboard,
  ...oppHeader,
  ...agent,
  ...outlet,
  ...product,
  ...order,
  ...paymentMethod,
  ...configurationHeader,
  ...user,
]
